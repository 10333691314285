/* eslint-env jquery */
/* globals app */

app.controller('anatomicalStructureController', ['$rootScope', '$scope', '$location', '$timeout', function ($rootScope, $scope, $location, $timeout) {
  var anatomicalStructure = this

  anatomicalStructure.init = function () {
    if ($location.hash()) {
      $rootScope.anatomicalNodeTab = $location.hash()
      anatomicalStructure.moveStateReview('in')
    } else {
      $rootScope.anatomicalNodeTab = 'overview'
    }
  }

  anatomicalStructure.moveStateReview = function (position) {
    if (position === 'in') {
      $timeout(function () {
        jQuery('#block-lumc-review-wrapper').prepend(jQuery('.block-lumc-review'))
      })
    } else if (position === 'out') {
      jQuery('.hidden-content').prepend(jQuery('.block-lumc-review'))
    }
  }

  anatomicalStructure.init()
}])

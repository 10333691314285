/* eslint-env jquery */
/* globals app, angular, URL */

app.controller('dataItemController', ['$scope', '$http', '$sce', function ($scope, $http, $sce) {
  $scope.dataItemDisplay = false
  $scope.loader = true
  $scope.item = {}
  $scope.materialReviewed = false
  $scope.filters = []

  $scope.updateBodyOverflow = function () {
    if ($scope.dataItemDisplay) {
      document.querySelector('body').style.overflow = 'hidden'
    } else {
      document.querySelector('body').style.overflow = 'auto'
    }
  }

  $scope.setReviewedStatus = function () {
    if (jQuery('.lumc_review_lumc_review_change_state .reviewed-material').length) {
      $scope.materialReviewed = true
    } else {
      $scope.materialReviewed = false
    }
  }

  $scope.triggerReviewed = function () {
    jQuery('.lumc_review_lumc_review_change_state .block-content a').click()
  }

  $scope.clickDataItem = function (nid) {
    $scope.dataItemDisplay = true
    $scope.loader = true
    $scope.updateBodyOverflow()

    var req = {
      method: 'GET',
      url: '/lumc-data-item/' + nid
    }

    $http(req).then(function (response) {
      $scope.item = {}
      $scope.infoDisplay = false
      $scope.loader = false

      $scope.view = 'default'
      $scope.item.commentStatus = response.data.node.comment
      $scope.item.url = response.data.url
      $scope.item.nid = response.data.node.nid
      $scope.item.title = response.data.node.title
      $scope.item.image = response.data.image
      $scope.item.mention = $sce.trustAsHtml(response.data.mention)
      $scope.item.institution = response.data.institution
      $scope.item.credits = response.data.credits
      $scope.item.infoImage = $sce.trustAsHtml(response.data.infoImage)
      $scope.item.expertAnatomical = 'todo'
      $scope.item.expertEducational = 'todo'
      $scope.item.expertFinishing = 'todo'
      $scope.item.userAnatomical = 'todo'
      $scope.item.userEducational = 'todo'
      $scope.item.userFinishing = 'todo'
      $scope.item.language = response.data.node.language
      $scope.item.educationalLevel = response.data.educationalLevel
      $scope.item.imageDirection = response.data.imageDirection
      $scope.item.textDirection = response.data.textDirection
      $scope.item.institutionImage = response.data.institutionImage
      $scope.item.technicalInfo = $sce.trustAsHtml(response.data.technicalInfo)
      $scope.item.license = response.data.license
      $scope.item.imageMetaData = response.data.imageMetaData
      $scope.item.link = response.data.link
      $scope.item.externalLink = response.data.externalLink
      $scope.item.infoText = response.data.infoText
      $scope.item.nodeDescription = $sce.trustAsHtml(response.data.nodeDescription)
      $scope.item.preNodeDescription = $sce.trustAsHtml(response.data.preNodeDescription)
      $scope.item.comments = response.data.comments
      $scope.item.commentsList = response.data.commentsList
      $scope.item.commentsTeaser = response.data.commentsTeaser
      $scope.item.commentsNumber = response.data.commentsNumber
      $scope.item.commentLink = response.data.commentLink
      $scope.item.downloadLink = response.data.downloadLink
      $scope.item.reviewText = response.data.reviewText
      $scope.item.review = $sce.trustAsHtml(response.data.review)
      $scope.item.canManage = response.data.canManage
      $scope.item.reviewAverage = response.data.reviewAverage
      $scope.item.licenseLink = $sce.trustAsHtml(response.data.licenseLink)
      $scope.item.staining = response.data.staining

      console.log(response.data)
    }, function (response) {
      console.error('The ajax request has encountered a problem')
    })
  }

  $scope.submitComment = function () {
    if (!$scope.comment) {
      return false
    }

    var req = {
      method: 'POST',
      url: '/lumc-data-item-submit-comment',
      data: {
        comment: $scope.comment,
        nid: $scope.item.nid
      }
    }

    $http(req).then(function (response) {
      $scope.comment = null
      $scope.view = 'comments'

      if (!$scope.item.commentsList) {
        $scope.item.commentsList = ''
      }

      $scope.item.commentsList = '<div class="author"><div class="left-part"><b>' + response.data.author + '</b></div>' +
                                       '<div class="right-part">' + response.data.date + '</div></div>' +
                                       '<div class="body"><i>' + response.data.comment + '</i></div>' +
                                       '<div class="separator mb-1 mt-1"></div>' +
                                       $scope.item.commentsList

      console.log(response.data)
    }, function (response) {
      console.error('The ajax request has encountered a problem')
    })
  }

  $scope.urlParamsHandler = function () {
    $scope.oldSearch = window.location.search

    var detect = function () {
      if ($scope.oldSearch !== window.location.search) {
        $scope.setFilters()
        $scope.oldSearch = window.location.search
      }
    }
    setInterval(function () {
      detect()
    }, 500)
  }

  $scope.reloadWithParams = function (removedParam) {
    var url = new URL(window.location.href)
    var baseUrl = url.origin + url.pathname
    var params = decodeURI(window.location.search)

    if (params.indexOf(removedParam) === -1) {
      params = params.replace(/%3A/g, ':')
    }

    console.log('removedParam', removedParam)
    console.log('urlParams', params)

    if (removedParam && params) {
      if (params.indexOf('&f[]=' + removedParam) !== -1) {
        params = params.replace('&f[]=' + removedParam, '')
      } else if (params.indexOf('f[]=' + removedParam + '&') !== -1) {
        params = params.replace('f[]=' + removedParam + '&', '')
      } else if (params.indexOf('f[]=' + removedParam)) {
        params = params.replace('f[]=' + removedParam, '')
      }

      if (params.length === 1) params = ''

      window.location.href = (baseUrl + params)
    }
  }

  $scope.getUrlParameter = function (sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1))
    var sURLVariables = sPageURL.split('&')
    var sParameterName
    var i
    var results = []

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=')

      if (sParameterName[0] === sParam) {
        results.push(sParameterName[1] === undefined ? true : sParameterName[1])
      }
    }

    return results
  }

  $scope.setFilters = function () {
    $scope.filters = []
    var filters = $scope.getUrlParameter('f[]')

    angular.forEach(filters, function (filter) {
      var name = ''
      var value = ''
      var disabled = false

      filter = filter.replace(/%3A/g, ':')

      for (var i = 0; i < filter.split(':').length - 1; i++) {
        name += filter.split(':')[i] + ':'
      }
      name = name.substring(0, name.length - 1)
      value = filter.split(':')[filter.split(':').length - 1]

      var label = jQuery('[type="checkbox"][data-raw-facet-name="' + name + '"][data-facet-value="' + value + '"] + label').text()

      if (label) {
        disabled = jQuery('[type="checkbox"][data-raw-facet-name="' + name + '"][data-facet-value="' + value + '"]').attr('disabled')
      } else {
        label = jQuery('select[name="' + name + '"] option[value="' + value + '"]').text()
      }

      if (label) {
        var uniq = true
        angular.forEach($scope.filters, function (_filter) {
          if (name === _filter.name && value === _filter.value) {
            uniq = false
          }
        })

        if (uniq) {
          $scope.filters.push({
            name: name,
            value: value,
            label: label,
            disabled: disabled
          })
        }
      }
    })
  }

  $scope.removeFilter = function (filter) {
    angular.forEach($scope.filters, function (_filter) {
      if (filter === _filter) {
        $scope.reloadWithParams(_filter.name + ':' + _filter.value)
      }
    })
  }

  $scope.setFilters()
  $scope.urlParamsHandler()
}])

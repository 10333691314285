/* eslint-env jquery */
/* globals angular, MutationObserver, videojs, jQuery3, Drupal */

var app = angular.module('lumcApp', ['ngAnimate', 'ngSanitize', 'angular-click-outside'])

app.directive('mutationObserver', ['$compile', function ($compile) {
  return function (scope, element, attrs) {
    var prevMutation = Date.now()
    var lastMutation = Date.now()
    var timeBetweenCompilations = 200 // 0.2s

    var compileDOM = function () {
      $compile(element.contents())(scope)
    }

    // Capture ajax dom updates
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.target.nodeName === 'MUTATION-OBSERVER') {
          lastMutation = Date.now()
          if ((lastMutation - prevMutation) > timeBetweenCompilations) {
            compileDOM()
          }
          prevMutation = Date.now()
        }
      })
    })

    observer.observe(element[0], {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true
    })
  }
}])

app.directive('backImg', function () {
  return function (scope, element, attrs) {
    attrs.$observe('backImg', function (value) {
      element.css({
        'background-image': 'url(' + value + ')'
      })
    })
  }
})

;(function ($, Drupal) {
  Drupal.behaviors.lumc = {
    attach: function (context, settings) {
      this.alterQuizGeneratorFilterDom()
      this.handleQuizGeneratorFilterClick()
      this.syncSearchDataItemFacetFilter()
      this.stickSearchDataItemMenu()

      // fix videojs issue
      if (typeof videojs !== 'undefined') {
        videojs(document.querySelector('.field-type-video video'))
      }

      if ($('.video-js').length) {
        $('.video-js').each(function () {
          var wrapperWidth = $('div#content-area').width()
          var videoWidth = $(this).width()
          var videoHeight = $(this).width()
          var videoRatio = $(this).width() / $(this).height()

          videoWidth = (videoWidth * 2 < wrapperWidth) ? videoWidth * 2 : wrapperWidth
          videoHeight = videoWidth / videoRatio

          $('.video-js').width(videoWidth).height(videoHeight)
        })
      }

      $('#edit-replicate-clone').click(function () {
        $('#edit-replicate').click()
      })

      $('.dataItemPanel .dataContent .lowerpart, .dataItemPanel .dataItemPanelContent .lowerpart, #quiz-container .dataContent .lowerpart').mCustomScrollbar({
        scrollInertia: 0
      })

      jQuery('body.search-data-item', context).once('showFacet', function () {
        $('body.search-data-item').on('click', 'div#sidebar-first [class*="facetapi_"]:not(.facetapi_GaDrMmh0X882d1MxKeWJekGuhSGowpyh) h3', function () {
          if ($(this).hasClass('open')) {
            $(this).removeClass('open')
            $(this).parent().children('.block-content').removeClass('open')
          } else {
            $(this).addClass('open')
            $(this).parent().children('.block-content').addClass('open')
          }
        })
      })

      $(document).on('focusin', '#fake-nid-field', function () {
        $(this).addClass('active')
        $('button#fake-nid-submit').addClass('active')
      })

      $(document).on('focusout', '#fake-nid-field', function () {
        var that = this
        setTimeout(function () {
          $(that).removeClass('active')
          $('button#fake-nid-submit').removeClass('active')
        }, 250)
      })

      $('#edit-submit-introductions-search').mouseleave(function () {
        $('#fake-nid-field').removeClass('active')
        $('body.no-menu-and-filter section#content #content-area .view .view-filters .form-submit').removeClass('active')
      })

      if ($('body.front').length) {
        $('body.front #content-area form#search-api-page-search-form input.form-autocomplete').focus()
      }

      if ($('body.page-node-take').length) {
        if (!$('input#edit-submit').length) {
          $('.right-side').removeClass('theme-gradient')
            .addclass('inactive')
        } else {
          $('.right-side').on('mouseenter', function () {
            $('input#edit-submit').addClass('active')
          })
          $('.right-side').on('mouseleave', function () {
            $('input#edit-submit').removeClass('active')
          })
          $('.right-side').on('click', function () {
            $('input#edit-submit').trigger('click')
          })

          $('input#edit-submit[value="Next"]').on('mouseenter', function () {
            $('.right-side').addClass('hover')
          })
          $('input#edit-submit[value="Next"]').on('mouseleave', function () {
            $('.right-side').removeClass('hover')
          })

          if ($('input#edit-submit[value="Finish"]').length) {
            $('.right-side').addClass('finish')

            $('input#edit-submit[value="Finish"]').on('mouseenter', function () {
              $('.right-side').addClass('active')
            })
            $('input#edit-submit[value="Finish"]').on('mouseleave', function () {
              $('.right-side').removeClass('active')
            })
          }
        }

        if (!$('input#edit-back').length) {
          $('.left-side').removeClass('theme-gradient')
            .addClass('inactive')
        } else {
          $('.left-side').on('mouseenter', function (e) {
            $('input#edit-back').addClass('active')
            if (e.target.className === 'top-part') {
              $('.left-side').addClass('noHover')
            }
          })
          $('.left-side').on('mouseleave', function (e) {
            $('input#edit-back').removeClass('active')
            if (e.target.className === 'top-part') {
              $('.left-side').removeClass('noHover')
            }
          })
          $('.left-side .top-part').on('mouseenter', function (e) {
            $('.left-side').addClass('noHover')
          })
          $('.left-side .top-part').on('mouseleave', function (e) {
            $('.left-side').removeClass('noHover')
          })
          $('.left-side .top-part').on('click', function (e) {
            e.stopPropagation()
          })
          $('.left-side').on('click', function (e) {
            $('input#edit-back').trigger('click')
          })

          $('input#edit-back').on('mouseenter', function () {
            $('.left-side').addClass('hover')
          })
          $('input#edit-back').on('mouseleave', function () {
            $('.left-side').removeClass('hover')
          })
        }
      }

      $(window).resize(function () {
        if ($('.mCustomScrollBox').length) {
          document.querySelector('.mCustomScrollBox').style.maxHeight = null
        }
      })
    },

    syncSearchDataItemFacetFilter: function () {
      var query = window.location.search
      $('body.search-data-item div#sidebar-first [class*="facetapi_"]').each(function () {
        var field = $(this).find('[data-raw-facet-name]').attr('data-raw-facet-name')

        if (typeof field !== 'undefined') {
          field = field.replace(/:/g, '%253A')

          if (query.indexOf(field) !== -1) {
            $(this).children('h3').addClass('open')
            $(this).children('.block-content').addClass('open')
          }
        }
      })
    },

    alterQuizGeneratorFilterDom: function () {
      if ($('body.page-do-quiz-generator').length) {
        $('form#lumc-quiz-generator-creation-form .form-type-select').each(function () {
          $(this).find('select').wrapAll('<div class="select-wrapper"/>')
        })
      }
    },

    handleQuizGeneratorFilterClick: function () {
      if ($('body.page-do-quiz-generator').length) {
        var $trigger = $('body.page-do-quiz-generator form#lumc-quiz-generator-creation-form > div .global-form-wrapper .form-wrapper > .form-item > label, body.page-do-quiz-generator form#lumc-quiz-generator-creation-form > div .global-form-wrapper .form-item-filter-container-language > label')
        $trigger.click(function () {
          if ($(this).hasClass('open')) {
            $(this).removeClass('open')
          } else {
            $(this).addClass('open')
          }
        })
      }
    },

    stickSearchDataItemMenu: function () {
      if ($('body.search-data-item').length && this.viewport().width > 768) {
        $('#sidebar-first .filters').sticky({
          topSpacing: 148 + 65
        })

        $(document).on('click', 'div#sidebar-first [class*="facetapi_"]:not(.facetapi_GaDrMmh0X882d1MxKeWJekGuhSGowpyh):not(.facetapi_T1qaOo1JX0Q53bIOwBbXXciobFxx6bXZ):not(.facetapi_U9qAiPSTMASHukvvGnORTf19IIwcfIJJ):not(.facetapi_CnOSAZIHseE2lD10QB4gL45Jw1WNSDfA) h3', function () {
          $('#sidebar-first .filters').sticky('update')
        })
      }
    },

    viewport: function () {
      var e = window
      var a = 'inner'
      if (!('innerWidth' in window)) {
        a = 'client'
        e = document.documentElement || document.body
      }
      return {
        width: e[a + 'Width'],
        height: e[a + 'Height']
      }
    }
  }
})(jQuery3, Drupal)

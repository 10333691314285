/* globals app */

app.controller('quizController', ['$scope', '$http', '$sce', function ($scope, $http, $sce) {
  var quiz = this

  quiz.loader = false
  quiz.displayPanel = false

  $scope.clickQuiz = function (nid) {
    quiz.loader = true
    quiz.displayPanel = true

    var req = {
      method: 'GET',
      url: '/lumc-quiz/get-nid/' + nid
    }

    $http(req).then(function (response) {
      quiz.loader = false
      quiz.title = response.data.title
      quiz.image = response.data.image
      quiz.thumbnail = response.data.thumbnail
      quiz.link = response.data.link
      quiz.mention = $sce.trustAsHtml(response.data.mention)
      quiz.institution = response.data.institution
      quiz.institutionImage = response.data.institutionImage
      quiz.infoImage = $sce.trustAsHtml(response.data.infoImage)
      quiz.description = $sce.trustAsHtml(response.data.description)
      quiz.educationalLevel = response.data.educationalLevel
      quiz.educational_level = response.data.educational_level
      quiz.imageDirection = response.data.imageDirection
      quiz.textDirection = response.data.textDirection
      quiz.license = response.data.license
      quiz.language = (response.data.node.language !== 'und') ? response.data.node.language : null
      quiz.technicalInfo = $sce.trustAsHtml(response.data.technicalInfo)
      quiz.canManage = response.data.canManage
      quiz.nid = response.data.node.nid
      quiz.url = response.data.url
      quiz.reviewText = response.data.reviewText
      quiz.review = $sce.trustAsHtml(response.data.review)
      quiz.reviewAverage = response.data.reviewAverage
      quiz.licenseLink = $sce.trustAsHtml(response.data.licenseLink)
      console.log(response.data)
    }, function (response) {
      console.error('The ajax request has encountered a problem')
    })
  }
}])

/* globals app, angular, terms, relationTermsMap */

app.controller('discoverController', ['$rootScope', '$scope', '$http', '$location', function ($rootScope, $scope, $http, $location) {
  var discover = this

  $scope.loader = false
  $scope.terms = terms
  $scope.hasImages = true
  $scope.currentTitle = 'Region'
  $scope.currentSubTitle = 'Select a region'
  $scope.level1 = '/sites/all/themes/lumc/templates/angular/nav-level1.html'
  $scope.level2 = '/sites/all/themes/lumc/templates/angular/nav-level2.html'
  $scope.level3 = '/sites/all/themes/lumc/templates/angular/nav-level3.html'
  $scope.currentLevel = 1
  $scope.currentTid = 0
  $scope.relations = []

  $rootScope.parentTid = -1
  $rootScope.anatomicalType = 'region'
  $scope.template = $scope.level1

  discover.init = function () {
    if ($location.hash() === 'region') {
      $rootScope.anatomicalType = $location.hash()
      $rootScope.anatMenuDisplay = true
      $rootScope.panelType = 'region'
    } else if ($location.hash() === 'systems') {
      $rootScope.anatomicalType = $location.hash()
      $rootScope.anatMenuDisplay = true
      $rootScope.panelType = 'systems'
      $scope.currentTitle = 'System'
      $scope.currentSubTitle = 'Select a system'
      discover.updateDatas($rootScope.anatomicalType, 0)
    } else if ($location.hash() !== '') {
      $rootScope.anatomicalNodeTab = $location.hash()
    }
  }

  discover.updateDatas = function (type, tid) {
    $scope.loader = true

    if (!tid) {
      tid = 0
    }

    if (!type) {
      type = 'region'
    }

    var req = {
      method: 'GET',
      url: '/lumc-anatomical-structures/get-structures/' + type + '/' + tid + '/' + $scope.currentLevel
    }

    $http(req).then(function (response) {
      $scope.loader = false
      $scope.terms = response.data.children
      $scope.hasImages = response.data.hasImages
      $rootScope.parentTid = response.data.parent_tid
      $rootScope.anatomicalType = response.data.type
      if (response.data.term) {
        $scope.template = $scope.level2
        $scope.currentTitle = response.data.term.name
        $scope.currentTid = response.data.term.tid
        $scope.general = false
        $scope.mainStructures = false
        $scope.regionsAndCompartments = false
        $scope.vesselsNervesLymphatics = false
        if (response.data.children.panel === 'general') {
          $scope.section = 'general'
        } else if (response.data.children.panel === 'main_structures') {
          $scope.section = 'mainStructures'
        } else if (response.data.children.panel === 'regions_and_compartments') {
          $scope.section = 'regionsAndCompartments'
        } else if (response.data.children.panel === 'vessels_nerves_lymphatics') {
          $scope.section = 'vesselsNervesLymphatics'
        } else if (response.data.children.panel === 'specific_structures') {
          $scope.section = 'specificStructures'
        } else if (response.data.children.panel === 'regional') {
          $scope.section = 'regional'
        }
      } else if ($rootScope.anatomicalType === 'region') {
        $scope.template = $scope.level1
        $scope.currentTitle = 'Region'
        $scope.currentSubTitle = 'Select a region'
        $scope.currentTid = 0
      } else if ($rootScope.anatomicalType === 'systems') {
        $scope.template = $scope.level1
        $scope.currentTitle = 'System'
        $scope.currentSubTitle = 'Select a system'
        $scope.currentTid = 0
      }
    }, function (response) {
      console.error('The ajax request has encountered a problem')
    })
  }

  discover.backLinkClick = function () {
    if ($scope.currentLevel > 1) {
      $scope.currentLevel--
    }
    if ($rootScope.parentTid === -1) {
      $rootScope.anatMenuDisplay = false
      $location.hash(null)
    } else {
      discover.updateDatas($rootScope.anatomicalType, $rootScope.parentTid)
    }
  }

  discover.clickOutside = function () {
    $rootScope.parentTid = 0
    $rootScope.anatMenuDisplay = false
    $location.hash(null)
  }

  discover.incrementLevel = function () {
    $scope.currentLevel++
  }

  discover.clickMore = function (relations, tid) {
    angular.forEach(relations, function (item) {
      if (item.tid === tid) {
        item.more = false
      }

      if (item.moreLink && item.tid === tid) {
        item.visible = false
      }
    })
  }

  discover.clickLevel2 = function (association, id, name, list) {
    $scope.loader = true
    $scope.relations = []

    $scope.list = typeof list !== 'undefined' ? list : false

    var req = {
      method: 'GET',
      url: '/anatomical-structures-relations/' + association + '/' + id
    }

    $http(req).then(function (response) {
      $scope.loader = false
      $scope.template = $scope.level3
      $scope.currentTitle = name
      $rootScope.parentTid = $scope.currentTid
      discover.incrementLevel()
      var items = []

      // Get all items
      angular.forEach(response.data.relations, function (type, tid) {
        angular.forEach(type.items, function (item, key) {
          item.tid = type.tid
          item.name = (item.direct) ? type.field_relation_text_value : type.field_inverse_relation_text_value
          items.push(item)
        })
      })

      // Sort by name
      items = items.sort(discover.sortItems)

      // Remove name if same as previous
      var prevName = null
      var i = 0
      angular.forEach(items, function (item) {
        var newName = item.name !== prevName
        prevName = item.name
        if (!newName) {
          item.name = null
          if (i === 2) {
            item.title = 'More...'
            item.moreLink = true
            item.visible = true
          }
          if (i > 2) {
            item.more = true
          } else {
            item.more = false
          }
          i++
        } else {
          i = 0
        }
        $scope.relations.push(item)
      })
    }, function (response) {
      console.error('The ajax request has encountered a problem')
    })
  }

  discover.sortItems = function (a, b) {
    if (a.name === b.name) {
      return (a.title < b.title) ? -1 : 1
    }

    a.rank = (a.direct) ? relationTermsMap[a.tid][0] : relationTermsMap[a.tid][1]
    b.rank = (b.direct) ? relationTermsMap[b.tid][0] : relationTermsMap[b.tid][1]

    return (a.rank < b.rank) ? -1 : 1
  }

  discover.clickHamburger = function (id, name) {
    $scope.loader = true
    $scope.relations = []

    var req = {
      method: 'GET',
      url: '/anatomical-structures-relations/1/' + id
    }

    $http(req).then(function (response) {
      $scope.loader = false
      $scope.currentTitle = name
      var items = []

      // Get all items
      angular.forEach(response.data.relations, function (type, tid) {
        angular.forEach(type.items, function (item, key) {
          item.tid = type.tid
          item.name = (item.direct) ? type.field_relation_text_value : type.field_inverse_relation_text_value
          items.push(item)
        })
      })

      // Sort by name
      items = items.sort(discover.sortItems)

      // Remove name if same as previous
      var prevName = null
      var i = 0
      angular.forEach(items, function (item) {
        var newName = item.name !== prevName
        prevName = item.name
        if (!newName) {
          item.name = null
          if (i === 2) {
            item.title = 'More...'
            item.moreLink = true
            item.visible = true
          }
          if (i > 2) {
            item.more = true
          } else {
            item.more = false
          }
          i++
        } else {
          i = 0
        }
        $scope.relations.push(item)
      })
    }, function (response) {
      console.error('The ajax request has encountered a problem')
    })
  }

  discover.init()
}])
